import styled from '@emotion/styled/macro';
import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';

import { Button } from '../UI/Button';
import { Column } from '../UI/Column';
import { Row } from '../UI/Row';

import { NewsItem } from './NewsItem';

const newsQuery = graphql`
    {
    allSanityNews {
      edges {
        node {
          id
          Description
          Slug
          Title
          Image {
            asset {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
  `;

export function NewsItems() {
  const [limit, setLimit] = useState<number>(2);
  const [maximumReached, setMaximumReached] = useState<boolean>(false);

  function loadMore() {
    setLimit(limit + 2);
  }

  return (
    <Container>
      <NewsItemsContainer >
        <StaticQuery
          query={newsQuery}
          render={news => {
            const items = news.allSanityNews.edges;

            if (items.length === limit) { setMaximumReached(true); }

            return (
              items.map((newsItem, key) => {
                const i = newsItem.node;
                if (key < limit) {
                  return (
                    <NewsItem key={key}
                      image={i.Image.asset.fluid}
                      title={i.Title}
                      description={i.Description}
                      slug={`/projecten/${i.Slug}`}
                    />
                  );
                }
                else {
                  return (null);
                }
              })
            );
          }}
        />
      </NewsItemsContainer>
      {!maximumReached && (
        <ShowMoreButton mode="grey" onClick={() => loadMore()}>
          Toon meer
        </ShowMoreButton>
      )}
    </Container>
  );
}

const Container = styled(Row)`
  display: flex;
  flex-direction: column;
`;

const NewsItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 5rem;
  flex-wrap: wrap;
`;

const ShowMoreButton = styled(Button)`
  color: white;
  margin: 3rem auto;
`; 