import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Text } from '../UI/Text';
import { UnstyledLink } from '../UI/UnstyledLink';

type Props = {
  text: string;
  link: string;
};

export function ServiceBlock({ text, link }: Props) {
  return (
    <StyledLink to={link}>
      <StyledText mode="normal">
        {text}
      </StyledText>
    </StyledLink>
  );
}

const StyledLink = styled(UnstyledLink)`
  max-width: 16rem;
  max-height: 11rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #132427;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.10);


  ${forBreakpoint(Breakpoint.Phone, css`
    width: 35vw;
    height: 27vw;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
  `)};

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-left: 2rem;
    margin-right: 2rem;
  `)};
`;

const StyledText = styled(Text)`
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
      font-size: 1.3rem;
  `)};
`;