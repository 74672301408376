import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../../types';
import { forBreakpoint } from '../../../utils/forBreakpoint';
import { Button } from '../../UI/Button';
import { NoSelect } from '../../UI/NoSelect';
import { Text } from '../../UI/Text';
import { UnstyledLink } from '../../UI/UnstyledLink';

import EtnaUrl from './Etna.jpg';
import smallLineUrl from './topleft1.svg';
import bigLineUrl from './topleft2.svg';

const LinesAlt = 'Background line';

export function Winepad() {
  return (
    <>
      <EtnaContainer>
        <EtnaText mode="normal">Etna, Sicilië</EtnaText>
        <Etna src={EtnaUrl} alt="Cantine Nicosia" />
        <DesktopBox>
          <BoxContent />
        </DesktopBox>
      </EtnaContainer>
      <MobileBox>
        <BoxContent />
      </MobileBox>
    </>
  );
}

function BoxContent() {
  return (
    <>
      <Title>Winepad</Title>
      <Info>Bezorg  je restaurantbezoekers de meest complete wijnervaring.</Info>
      <StyledButton mode="white" asDiv>
        <UnstyledLink to="https://www.wine-pad.com/" target="_blank">
          Ontdek Winepad
        </UnstyledLink>
      </StyledButton>

      <Line top left src={smallLineUrl} alt={LinesAlt} />
      <Line top left src={bigLineUrl} alt={LinesAlt} />
      <Line top right src={smallLineUrl} alt={LinesAlt} />
      <Line top right src={bigLineUrl} alt={LinesAlt} />
      <Line bottom left src={smallLineUrl} alt={LinesAlt} />
      <Line bottom left src={bigLineUrl} alt={LinesAlt} />
      <Line bottom right src={smallLineUrl} alt={LinesAlt} />
      <Line bottom right src={bigLineUrl} alt={LinesAlt} />
    </>
  );
}

const Line = styled.img<{ left?: boolean, right?: boolean, top?: boolean, bottom?: boolean }>`
  ${NoSelect()}

  position: absolute;

  ${(props) => {
    if (props.top && props.left) {
      return css`
        top: 0;
        left: 0;
        transform: rotate(90deg);
      `;
    }
    if (props.top && props.right) {
      return css`
        right: 0;
        top: 0;
      `;
    }

    if (props.bottom && props.left) {
      return css`
        bottom: 0;
      `;
    }
    if (props.bottom && props.right) {
      return css`
        bottom: 0;
        right: 0;
        transform: rotate(90deg);
      `;
    }
  }}
`;

const Title = styled.div`
  font-family: Leitura Display;
  font-size: 2.5rem;
  color: #4E2100;

  margin-bottom: 1rem;
`;
const Info = styled.div`
  font-family: Lato;
  color: #000000;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const StyledButton = styled(Button)`
  margin-top: 3rem;
  background: #EFE8D2;
  color: #4E2100;
  font-size: 1rem;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-top: 6rem;
  `)}
`;

const EtnaContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  z-index: 1;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
      height: 30rem;

      margin-bottom: 10rem;
      display: flex;
  `)}
`;

const Etna = styled.img`
  ${NoSelect()}

  overflow-x: hidden;
  width: 105vw;
  max-height: 20rem;
  object-fit: cover;
  left: -15vw;

  position: relative;
  z-index: 0;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    position: absolute;
    width: 80%;
    height: 100%;
    object-fit: cover;
    max-height: unset;
    left: 15%;
    opacity: 0.8;
    border-radius: 20px;
  `)}

  ${forBreakpoint(Breakpoint.Desktop, css`
  `)}

  opacity: 0.8;
`;

const EtnaText = styled(Text)`
  position: absolute;
  z-index: 1;
  margin-top: 1.5rem;
  right: -0.5rem;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    color: white;
    top: -0.65rem;
    left: 15%;
    width: 77.5%;
    text-align: right;
  `)}

  ${forBreakpoint(Breakpoint.Desktop, css`
    top: 0rem;
  `)}
`;

function BoxStyling() {
  return css`
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;

    -webkit-box-shadow: 0px 0px 47px -13px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 47px -13px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 47px -13px rgba(0,0,0,0.75);

    background: #FCFFE8;
    border-radius: 20px;
  `;
}

const MobileBox = styled.div`
  ${BoxStyling()}

  width: 100%;
  height: 13.5rem;

  max-width: 25rem;

  left: 0;
  right: 0;
  top: -5rem;
  margin-left: auto;
  margin-right: auto;

  padding-top: 2rem;

  position: relative;
  z-index: 2;

  ${forBreakpoint(Breakpoint.TabletPortrait, css` 
    top: -10rem;
  `)}
  
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: none;
  `)}
`;
const DesktopBox = styled.div`
  ${BoxStyling()}

  display: none;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: flex;

    position: absolute;
    width: 100%;
    height: 62.5%;

    max-width: 30rem;
    padding-top: 3rem;
  `)}
`;