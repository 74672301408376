import styled from '@emotion/styled/macro';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { Breakpoint } from '../../types';
import { Column } from '../UI/Column';
import { Text } from '../UI/Text';
import { UnstyledLink } from '../UI/UnstyledLink';

type Props = {
  image: any,
  title: string,
  description: string,
  slug: string
};

export function NewsItem({ image, title, description, slug }: Props) {
  return (
    <StyledItem direction="column" breakpoints={{ [Breakpoint.Desktop]: 5 }}>
      <UnstyledLink to={slug}>
        <StyledImg fluid={image} />
        <TextContainer>
          <Text mode="projectText">{title}</Text>
          <StyledText mode="normal" asDiv={true}>
            {description}
          </StyledText>
          <UnderlineText mode="normal">
            Lees meer
          </UnderlineText>
        </TextContainer>
      </UnstyledLink>
    </StyledItem>
  );
}

const StyledItem = styled(Column)`
  background: #132427;
  width: 100%;
`;

const StyledImg = styled(Img)`
  max-width: 530px;
  max-height: 318px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 10rem;
  margin: 2rem 2rem;
`;

const UnderlineText = styled(Text)`
  width: fit-content;
  text-decoration: underline;
  text-underline-position: under;
  margin-top: 2rem;
  
  font-weight: 100; 
    &:hover {
    opacity: 0.7;
  } 
`;

const StyledText = styled(Text)`
  font-weight: 100; 
  margin-top: 1.5rem;
  padding-right: 3rem;
`;