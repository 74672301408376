import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Heading } from '../UI/Heading';

export function OurClients({ clients }) {
  return (
    <>
      <Heading type="h2">Onze Klanten</Heading>
      <ClientsContainer>
        {clients.map((client, key) => {
          const logoUrl = client.Photo.asset.fluid.src;

          return (
            <ImgContainer key={key}>
              <a href={client.Url} target="_blank">
                <LogoImg src={logoUrl} alt={client.Name} />
              </a>
            </ImgContainer>
          );
        })}
      </ClientsContainer>
    </>
  );
}

const ClientsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 10rem;
  flex-wrap: wrap;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
     margin-bottom: 20rem;
  `)}
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${COLORS.MediumGray};
  margin-right: 3px;
  margin-bottom: 3px;
  
  min-width: 120px;
  min-height: 120px;

  cursor: pointer;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    min-height: 190px;
    min-width: 190px;
  `)}
`;

const LogoImg = styled.img`
  margin: 2rem;
  width: 60px;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 100px;
  `)}
`;