import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

type Props = {
  children: any;
};

export function Mobile({ children }: Props) {
  return (
    <StyledContent>
      {children}
    </StyledContent>
  );
}

const StyledContent = styled.div`
  display: block;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: none;
  `)};
`;