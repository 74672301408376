import { css } from '@emotion/core';

export function IgnoreMaxWidth() {
  return css`
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  `;
}