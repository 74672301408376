import styled from '@emotion/styled/macro';
import React from 'react';

import { IgnoreMaxWidth } from '../IgnoreMaxWidth';

export function VerticalLine() {
  return <StyledLine />;
}

const StyledLine = styled.div`
  width: 100vw;
  height: 1px;

  position: relative;
  left: 0;

  background: #1B2B2E;
  ${IgnoreMaxWidth};
`;